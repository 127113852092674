import React from 'react';
import TitleImg from '../img/Dog Park.png'


export default function Title() {
    return (
        <>
            <img src={TitleImg} className="img-fluid text-center titleImg pb-5" alt='title banner that says Dog Park Scheduler' />
        </>

    )
}